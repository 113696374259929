// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import "bootstrap";

.pb-6 {
    padding-bottom: ($spacer * 15) !important;
}



/* ------- customize primary button color -------- */
$rheintec_forest: #3cb9ac;
$rheintec_blue: #165c8f;
$rheintec_light_blue: #8eb9dc;
$rheintec_dark: #17242d;



.btn-primary {
    @include button-variant($rheintec_blue, darken($rheintec_blue, 7.5%), darken($rheintec_blue, 10%), lighten($rheintec_blue, 5%), lighten($rheintec_blue, 10%), darken($rheintec_blue, 30%));
}

.btn-outline-primary {
    @include button-outline-variant($rheintec_blue, #222222, lighten($rheintec_blue, 5%), $rheintec_blue);
}